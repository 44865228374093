<script setup>
import { onMounted, ref } from 'vue';
import { appear as vAppear } from '@/Directives/Appear.js';

const props = defineProps({
    type: {
        type: String,
        required: false,
        default: 'fade',
    },
    infinity: {
        type: Boolean,
        default: false,
    },
})

const target = ref();
const animate = ref(false);

onMounted(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (props.infinity) {
                animate.value = entry.isIntersecting;
                return;
            }

            if (entry.isIntersecting && !animate.value) {
                animate.value = true;
            }
        },
        {
            threshold: 0,
        },
    );

    observer.observe(target.value);
});
</script>

<template>
    <div ref="target">
        <transition :name="type">
            <div v-appear="animate" class="animated-component">
                <slot />
            </div>
        </transition>
    </div>
</template>

<style scoped>
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}

/* Slide Fade animation */

.slide-fade-enter-active {
    transition: all 300ms ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>

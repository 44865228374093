<script setup>
import { ref, onMounted, computed } from 'vue';
import { router } from '@inertiajs/vue3';
import Tabs from '@/Components/Tabs.vue';
import OrderItem from '@/Pages/Orders/Partials/OrderItem.vue';
import ChooseCourseBanner from '@/Components/Banners/ChooseCourseBanner.vue';

const props = defineProps({
    active_orders: Array,
    expired_orders: Array,
});

const selectedTab = ref('active');

const tabs = computed(() => {
    const items = [];

    if (props.active_orders.length > 0) {
        items.push({
            id: 'active',
            title: 'text.active_courses',
            badge: props.active_orders.length,
        });
    }

    if (props.expired_orders.length > 0) {
        items.push({
            id: 'expired',
            title: 'text.expired_courses',
        });
    }

    return items;
});

const selectedTabItem = computed(() => tabs.value.find((tab) => tab.id === selectedTab.value));

const orders = computed(() => {
    if (selectedTab.value === 'active') {
        return props.active_orders;
    }

    if (selectedTab.value === 'expired') {
        return props.expired_orders;
    }

    return [];
});

onMounted(() => {
    selectedTab.value = tabs.value.find((tab) => router.page.url.endsWith(`#${tab.id}`))?.id ?? 'active';
});
</script>

<template>
    <MetaHead :title="orders.length > 0 ? `${__(selectedTabItem.title)} | ${__('page.orders.meta_title')}` : __('page.orders.meta_title')"/>

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529]"
            v-text="__('page.orders.title')"
        />

        <p
            class="text-sm text-[#524E56] mt-2"
            v-text="__('page.orders.text')"
        />

        <Tabs
            v-if="orders.length > 0"
            v-model="selectedTab"
            :tabs="tabs"
            class="mt-8"
        >
            <div class="flex flex-col gap-8">
                <OrderItem
                    v-for="order in orders"
                    :key="order.id"
                    :order="order"
                />
            </div>
        </Tabs>

        <ChooseCourseBanner v-else class="mt-8"/>
    </div>
</template>

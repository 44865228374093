import { defineStore } from 'pinia';

export const useModalsStore = defineStore('modals', {
    state: () => ({
        help: {
            visible: false,
        },
    }),
    actions: {
        showHelp() {
            this.help.visible = true;
        },
        hideHelp() {
            this.help.visible = false;
        },
    },
    getters: {
        isHelpVisible: (state) => state.help.visible,
    },
});

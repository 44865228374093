<script setup>
import ContentHeading from './Parts/Heading.vue';
import ContentParagraph from './Parts/Paragraph.vue';
import ContentImage from './Parts/Image.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const contentTypes = {
    heading: ContentHeading,
    paragraph: ContentParagraph,
    image: ContentImage,
};
</script>

<template>
    <div class="flex flex-col gap-6">
        <component
            v-for="(item, key) in content"
            :key="key"
            :is="contentTypes[item.type]"
            v-bind="item.data"
        />
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/AuthLayout.vue';
import Checkbox from '@/Components/Form/Checkbox.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';

defineOptions({
    layout: Layout,
});

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <MetaHead :title="__('page.login.meta_title')" />

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529] text-center mb-8"
            v-text="__('page.login.title')"
        />

        <p
            class="mb-8 text-sm text-gray-600 text-center"
            v-text="__('page.login.text')"
        />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel :value="__('label.email')" for="email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="email"
                />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <div class="flex justify-between">
                    <InputLabel :value="__('label.password')" for="password" />

                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="underline text-sm text-[#524E56]"
                        v-text="__('text.forgot_your_password')"
                    />
                </div>

                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="current-password"
                />
                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember" />
                    <span class="ms-2 text-sm text-gray-600" v-text="__('label.remember_me')"/>
                </label>
            </div>

            <PrimaryButton
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                class="w-full mt-8"
                v-text="__('cta.login')"
            />

            <div class="text-sm text-[#524E56] text-center mt-8">
                {{ __('text.do_not_have_an_account')}}

                <Link
                    :href="route('register')"
                    class="underline"
                    v-text="__('cta.register')"
                />
            </div>
        </form>
    </div>
</template>

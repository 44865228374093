<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useUser } from '@/Composables/useUser.js';

const page = usePage();
const user = useUser();

const initials = computed(() => {
    return user.value.name
        .split(' ')
        .slice(0, 2)
        .map((word) => word.charAt(0))
        .join('');
});
</script>

<template>
    <span
        class="flex items-center justify-center h-10 w-10 bg-[#F0F0F2] text-sm font-medium text-[#524E56] uppercase rounded-full"
        v-text="initials"
    />
</template>

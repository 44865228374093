<script setup>
import { ref, computed } from 'vue';
import { ArrowLeftIcon } from '@heroicons/vue/24/solid';
import BlankLayout from '@/Layouts/BlankLayout.vue';
import Navbar from './Partials/Watch/Navbar.vue';
import VideoPlayer from './Partials/Watch/VideoPlayer.vue';
import LessonOverview from './Partials/Watch/LessonOverview.vue';
import LessonsSidebar from './Partials/Watch/LessonsSidebar.vue';

defineOptions({
    layout: BlankLayout,
});

const props = defineProps({
    course: Object,
    lesson: Object,
    playback: Object,
    video_url: String,
});

const selectedTab = ref('overview');

const isSelectedOverviewTab = computed(() => selectedTab.value === 'overview');
const isSelectedLessonsTab = computed(() => selectedTab.value === 'lessons');
</script>

<template>
    <MetaHead :title="__('page.course_watch.meta_title', { lesson: lesson.title })"/>

    <div class="min-h-screen w-full bg-[#E4EFF1] md:flex md:flex-row md:bg-[#F0F0F2]">
        <main class="w-full">
            <Navbar :course="course" />

            <div class="px-6 py-8">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <Link :href="route('courses.show', course.slug)" class="flex items-center gap-3 text-sm text-[#524E56] mb-6">
                        <ArrowLeftIcon class="h-4 w-4"/>
                        {{ __('cta.go_back') }}
                    </Link>

                    <div class="flex flex-col bg-white rounded-lg md:flex-row">
                        <div class="grow">
                            <video-player
                                v-if="video_url"
                                :course="course"
                                :lesson="lesson"
                                :playback="playback"
                                :src="video_url"
                            />

                            <div class="grid grid-cols-2 text-center md:hidden">
                                <div
                                    :class="{
                                        'text-[#272529] border-b-2 border-[#272529]': isSelectedOverviewTab,
                                        'text-[#524E56] border-b border-[#DEDEE3]': !isSelectedOverviewTab,
                                    }"
                                    class="font-semibold py-4"
                                    v-text="__('text.lesson')"
                                    @click="selectedTab = 'overview'"
                                />

                                <div
                                    :class="{
                                        'text-[#272529] border-b-2 border-[#272529]': isSelectedLessonsTab,
                                        'text-[#524E56] border-b border-[#DEDEE3]': !isSelectedLessonsTab,
                                    }"
                                    class="font-semibold py-4"
                                    v-text="__('text.course')"
                                    @click="selectedTab = 'lessons'"
                                />
                            </div>

                            <LessonOverview
                                :class="{
                                    'block': isSelectedOverviewTab,
                                    'hidden md:block': !isSelectedOverviewTab,
                                }"
                                :course="course"
                                :lesson="lesson"
                            />
                        </div>

                        <LessonsSidebar
                            :class="{
                                'block': isSelectedLessonsTab,
                                'hidden md:block': !isSelectedLessonsTab,
                            }"
                            :course="course"
                            :lesson="lesson"
                        />
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import TextareaInput from '@/Components/Form/TextareaInput.vue';
import ActionMessage from '@/Components/ActionMessage.vue';

const page = usePage();

const form = useForm({
    name: '',
    phone: '',
    email: '',
    message: '',
});

const formSucceed = ref(false);
const settings = computed(() => page.props.settings);

const handleForm = async () => {
    if (form.processing) return;

    form.clearErrors();
    form.processing = true;

    await window.axios.post(route('api::contact.send'), form.data())
        .then(() => {
            form.reset();
            formSucceed.value = true;

            const interval = setInterval(() => {
                formSucceed.value = false;
                clearInterval(interval);
            }, 3000);
        })
        .catch((e) => {
            const response = e.response;

            if (response.status === 422) {
                Object.keys(response.data.errors).map((property) => {
                    form.setError(property, response.data.errors[property][0]);
                });
            }
        })
        .finally(() => {
            form.processing = false;
        });
};
</script>

<template>
    <div class="relative bg-[#E4EFF1] overflow-hidden px-6 py-12 md:py-16">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-12 md:grid-cols-2">
                <div>
                    <img src="/img/landing/logo_black.png" alt=""/>

                    <div class="flex flex-col gap-4 text-xl text-[#524E56] mt-11.5">
                        <a
                            v-if="settings.contact_email"
                            :href="`mailto:${settings.contact_email}`"
                        >
                            {{ settings.contact_email }}
                        </a>

                        <a
                            v-if="settings.contact_phone"
                            :href="`tel:${settings.contact_phone.trim()}`"
                        >
                            {{ settings.contact_phone }}
                        </a>

                        <div>Antras planas, UAB</div>
                    </div>
                </div>

                <div>
                    <h2
                        class="text-3xl font-semibold text-[#272529]"
                        v-text="__('text.start_working_together')"
                    />

                    <p
                        class="text-[#524E56] mt-6"
                        v-text="__('text.we_are_ready_to_help_you')"
                    />

                    <form
                        class="grid gap-6 mt-6 md:grid-cols-2"
                        @submit.prevent="handleForm"
                    >
                        <div>
                            <InputLabel :value="`${__('label.name')} *`" for="name" />
                            <TextInput
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="mt-1 block w-full"
                                :placeholder="__('placeholder.enter_name')"
                                autocomplete="name"
                            />
                            <InputError class="mt-2" :message="form.errors.name" />
                        </div>

                        <div>
                            <InputLabel :value="__('label.phone_number')" for="phone" />
                            <TextInput
                                id="phone"
                                v-model="form.phone"
                                type="tel"
                                class="mt-1 block w-full"
                                :placeholder="__('placeholder.enter_phone_number')"
                                autocomplete="phone"
                            />
                            <InputError class="mt-2" :message="form.errors.phone" />
                        </div>

                        <div class="col-span-full">
                            <InputLabel :value="`${__('label.email')} *`" for="email" />
                            <TextInput
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="mt-1 block w-full"
                                :placeholder="__('placeholder.enter_email')"
                                autocomplete="username"
                            />
                            <InputError class="mt-2" :message="form.errors.email" />
                        </div>

                        <div class="col-span-full">
                            <InputLabel :value="`${__('label.message')} *`" for="message" />
                            <TextareaInput
                                id="message"
                                v-model="form.message"
                                class="mt-1 block w-full"
                                :placeholder="__('placeholder.message_contact')"
                            />
                            <InputError class="mt-2" :message="form.errors.message" />
                        </div>

                        <div class="flex flex-col items-center gap-4 md:flex-row">
                            <button
                                :disabled="form.processing"
                                type="submit"
                                class="inline-block w-full bg-[#272529] text-sm font-semibold text-white border border-transparent rounded-lg px-4 py-3 md:w-auto disabled:opacity-70"
                            >
                                {{ __('cta.send') }}
                            </button>

                            <ActionMessage :on="formSucceed" class="me-3">
                                {{ __('flash_success.message_sent') }}
                            </ActionMessage>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="hidden absolute bottom-0 -left-[50px] h-[40px] w-3/4 bg-[#C9DEE2] shadow-[#6363631A] rotate-[15deg] md:block"/>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import AnimatedComponent from '@/Components/AnimatedComponent.vue';
import ReviewItem from './ReviewItem.vue';

const props = defineProps({
    reviews: {
        type: Array,
        required: true,
    },
});

const items = computed(() => {
    return [...props.reviews].map((review) => {
        review.colspan = null;

        return review;
    });
});

onMounted(() => {
    calculateColspan();
});

const calculateColspan = () => {
    const columns = 3;
    const rows = getRows(items.value.length, columns);
    let reviewsFrom = 0;
    let reviewsTo = columns;

    for (let i = 1; i <= rows; i++) {
        if (reviewsFrom === null && reviewsTo === null) {
            continue;
        }

        const currentRowReviews = items.value.slice(reviewsFrom, reviewsTo);
        const currentRowLength = currentRowReviews.length;

        const nextRowReviews = items.value.slice(reviewsFrom + columns, reviewsTo + columns);
        const nextRowLength = nextRowReviews.length;

        if (currentRowLength === 3 && (nextRowLength === 0 || nextRowLength === 3)) {
            setColspan(reviewsFrom, reviewsTo, 2);

            reviewsFrom += columns;
            reviewsTo += columns;

            continue;
        }

        if (currentRowLength === 3 && nextRowLength === 2) {
            setColspan(reviewsFrom, reviewsTo, 2);
            setColspan(reviewsFrom + columns, reviewsTo + columns, 3);

            reviewsFrom = null;
            reviewsTo = null;

            continue;
        }

        if (currentRowLength === 3 && nextRowLength === 1) {
            setColspan(reviewsFrom, reviewsTo + columns, 3);

            reviewsFrom = null;
            reviewsTo = null;

            continue;
        }

        if (currentRowLength === 1) {
            setColspan(reviewsFrom, reviewsTo, 6);
        }

        if (currentRowLength === 2) {
            setColspan(reviewsFrom, reviewsTo, 3);
        }

        reviewsFrom += columns;
        reviewsTo += columns;
    }
};

const getRows = (totalItems, columns) => {
    return Math.floor(totalItems / columns) && totalItems % columns === 0
        ? totalItems / columns
        : Math.floor(totalItems / columns) + 1;
};

const setColspan = (from, to, colspan = 2) => {
    return items.value
        .slice(from, to)
        .map((review) => {
            review.colspan = colspan;

            return review;
        });
};
</script>

<template>
    <section class="px-6">
        <div class="relative container max-w-7xl mx-auto bg-[#E4EFF1] overflow-hidden px-6 pt-12 pb-6 md:p-17">
            <div class="relative flex flex-col items-center text-center gap-6 z-10">
                <h2
                    class="text-3xl font-semibold text-[#272529]"
                    v-text="__('page.home.reviews.title')"
                />

                <p
                    class="text-[#524E56] md:max-w-[50%]"
                    v-html="__('page.home.reviews.text')"
                />
            </div>

            <AnimatedComponent>
                <div class="grid md:grid-cols-6 gap-4 mt-8">
                    <ReviewItem
                        v-for="review in items"
                        :key="review.id"
                        :review="review"
                        :class="{
                            'md:col-span-2': review.colspan === 2,
                            'md:col-span-3': review.colspan === 3,
                            'md:col-span-6': review.colspan === 6,
                        }"
                        class="z-10"
                    />
                </div>

                <div class="absolute -translate-y-3/4 bottom-3/4 -translate-x-1/2 left-1/2 h-[40px] w-screen bg-[#C9DEE2] shadow-[#6363631A] -rotate-[15deg] md:-translate-y-1/2 md:bottom-1/2"/>
            </AnimatedComponent>
        </div>
    </section>
</template>

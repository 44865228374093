<script setup>
import { computed } from 'vue';
import { Head, usePage } from '@inertiajs/vue3';
import { useString } from '@/Composables/useString.js';

const page = usePage();
const { truncate } = useString();

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        default: null,
    },
});

const siteName = computed(() => page.props.app.name);
const pageTitle = computed(() => `${props.title} | ${siteName.value}`);
const socialShareImage = computed(() => `${page.props.ziggy.url}/img/social/share.png`);
const canonicalUrl = computed(() => page.props.ziggy.location);

const metaDescription = computed(() => {
    if (!props.description) {
        return null;
    }

    return truncate(props.description, 160);
});
</script>

<template>
    <Head :title="title">
        <!-- Global -->
        <link rel="canonical" :href="canonicalUrl" />
        <meta name="apple-mobile-web-app-title" :content="pageTitle" />
        <meta v-if="metaDescription" name="description" :content="metaDescription" />

        <!-- Facebook & Linkedin -->
        <meta property="og:title" :content="pageTitle" />
        <meta v-if="metaDescription" property="og:description" :content="metaDescription" />
        <meta property="og:type" content="website" />
        <meta property="og:image" :content="socialShareImage" />
        <meta property="og:site_name" :content="siteName" />
        <meta property="og:url" :content="canonicalUrl" />

        <!-- Twitter -->
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" :content="pageTitle" />
        <meta v-if="metaDescription" name="twitter:description" :content="metaDescription" />
        <meta name="twitter:image" :content="socialShareImage" />
    </Head>
</template>

<template>
    <div class="min-h-screen flex bg-[#F0F0F2] overflow-hidden">
        <div
            class="w-full min-h-screen flex flex-col justify-center items-center sm:w-1/2"
        >
            <div
                class="w-full sm:max-w-md mt-2 sm:mt-6 px-6 pt-4 pb-8 overflow-hidden sm:rounded-lg"
            >
                <Link :href="route('home')">
                    <img src="/img/logo.svg" alt="logo" class="mx-auto mb-8"/>
                </Link>

                <slot />
            </div>
        </div>

        <div class="relative hidden h-screen sm:w-1/2 sm:flex sm:justify-center sm:items-center overflow-hidden">
            <div class="h-full w-full bg-[url('/img/auth-layout-bg.png')] bg-center bg-no-repeat bg-cover"/>
        </div>
    </div>
</template>

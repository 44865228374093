import './bootstrap';
import './analytics.js';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createPinia } from 'pinia';
import AppLayout from './Layouts/AppLayout.vue';
import MetaHead from './Components/Layout/MetaHead.vue';
import translationMixin from './Mixins/translation';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';
const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]

        page.default.layout = page.default.layout || AppLayout

        return page
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .component('Link', Link)
            .component('MetaHead', MetaHead)
            .mixin(translationMixin)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

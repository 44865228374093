import { UserIcon, EyeIcon } from '@heroicons/vue/24/outline';

const items = [
    {
        title: 'page.profile.nav_title',
        icon: UserIcon,
        route: 'profile.show',
        routeHash: '#profile',
    },
    {
        title: 'page.password_change.nav_title',
        icon: EyeIcon,
        route: 'profile.show',
        routeHash: '#password',
    },
];

export default items;

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from 'vue';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline';
import AnimatedComponent from '@/Components/AnimatedComponent.vue';
import TeamMemberItem from './TeamMemberItem.vue';

defineProps({
    members: {
        type: Array,
        required: true,
    },
});

const currentSlide = ref(0);

const sliderBreakpoints = ref({
    768: {
        itemsToShow: 3.5,
        snapAlign: 'center',
    },
});

const slidePrev = () => {
    currentSlide.value -= window.innerWidth >= 768 ? 3 : 1;
};

const slideNext = () => {
    currentSlide.value += window.innerWidth >= 768 ? 3 : 1;
};
</script>

<template>
    <section class="overflow-x-hidden px-6 py-12 md:py-17">
        <div class="container max-w-7xl mx-auto">
            <div class="flex flex-col items-center text-center gap-6">
                <h2
                    class="text-3xl font-semibold text-[#272529]"
                    v-text="__('page.home.team.title')"
                />

                <p
                    class="text-[#524E56]"
                    v-html="__('page.home.team.text')"
                />
            </div>

            <AnimatedComponent type="zoom">
                <Carousel
                    v-model="currentSlide"
                    :items-to-show="1"
                    :breakpoints="sliderBreakpoints"
                    class="mt-6 md:-mx-3"
                    @slide-end="console.log('asd')"
                >
                    <Slide
                        v-for="member in members"
                        :key="member.id"
                    >
                        <TeamMemberItem :member="member"/>
                    </Slide>
                </Carousel>

                <div class="flex items-center justify-center gap-4 mt-6">
                    <button
                        class="border border-[#DEDEE3] rounded-lg px-4 py-3"
                        @click="slidePrev"
                    >
                        <ArrowLeftIcon class="h-5 w-5 text-[#524E56]"/>
                    </button>

                    <button
                        class="border border-[#DEDEE3] rounded-lg px-4 py-3"
                        @click="slideNext"
                    >
                        <ArrowRightIcon class="h-5 w-5 text-[#524E56]"/>
                    </button>
                </div>
            </AnimatedComponent>
        </div>
    </section>
</template>

<style>
@media (min-width: 768px) {
    section.carousel .carousel__viewport .carousel__slide {
        padding-left: 12px;
        padding-right: 12px;
    }
}
</style>

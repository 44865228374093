<script setup>
import { computed } from 'vue';
import { useString } from '@/Composables/useString.js';
import CourseExperienceLevel from '@/Components/Course/CourseExperienceLevel.vue';
import CourseLessonsCount from '@/Components/Course/CourseLessonsCount.vue';
import CourseExpiresAt from '@/Components/Course/CourseExpiresAt.vue';
import CourseProgressBar from '@/Components/Course/CourseProgressBar.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';

const { truncate } = useString();

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
});

const course = computed(() => props.order.course);
const isExpired = computed(() => props.order.is_expired);
const lessonsCount = computed(() => course.value.lessons_count);
</script>

<template>
    <div class="flex flex-col bg-white rounded-lg md:flex-row">
        <img :src="course.image_url" alt="" class="w-full md:max-w-[350px] md:object-cover"/>

        <div class="w-full p-6 overflow-hidden">
            <div
                v-if="order.playbacks_runtime"
                class="inline-flex items-center justify-center bg-[#F0F0F2] text-xs font-medium text-[#272529] rounded-full px-2 py-0.5"
                v-text="__('text.started')"
            />

            <h2
                class="font-semibold text-[#272529] mt-2.5"
                v-text="course.title"
            />

            <div
                v-if="course.content"
                class="text-sm text-[#524E56] mt-2.5"
                v-html="truncate(course.content, 200)"
            />

            <div class="flex flex-col items-start gap-4 mt-4 xl:flex-row xl:gap-16 xl:items-center">
                <CourseExperienceLevel :level="course.experience_level"/>
                <CourseLessonsCount :count="lessonsCount"/>
                <CourseExpiresAt v-if="!isExpired" :date="order.expires_at"/>
            </div>

            <div class="block h-px bg-[#DEDEE3] mt-4"/>

            <div
                class="flex flex-col items-start justify-between gap-4 mt-4 xl:flex-row xl:items-center xl:gap-0"
            >
                <template v-if="isExpired">
                    <div/>

                    <PrimaryButton
                        v-text="__('cta.extend_validity')"
                    />
                </template>

                <template v-else>
                    <CourseProgressBar
                        :runtime="order.playbacks_runtime"
                        :total-runtime="course.lessons_runtime"
                        class="md:w-full xl:w-80"
                    />

                    <PrimaryLink :href="route('courses.show', course.slug)">
                        {{ order?.playbacks_runtime > 0 ? __('cta.keep_learning') : __('cta.start_course') }}
                    </PrimaryLink>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { router } from '@inertiajs/vue3';
import { ChevronDownIcon, ArrowRightStartOnRectangleIcon, ChatBubbleLeftIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/vue/24/outline';
import { useUser } from '@/Composables/useUser.js';
import Dropdown from '@/Components/Layout/App/Partials/Dropdown.vue';
import DropdownLink from '@/Components/Layout/App/Partials/DropdownLink.vue';
import NavLink from '@/Components/Layout/App/Partials/NavLink.vue';
import ResponsiveNavLink from '@/Components/Layout/App/Partials/ResponsiveNavLink.vue';
import Avatar from '@/Components/Layout/App/Partials/Avatar.vue';
import SidebarMenuItems from '@/Data/SidebarMenuItems.js';
import ProfileMenuItems from '@/Data/ProfileMenuItems.js';
import { useModalsStore } from '@/Stores/modals.js';

const user = useUser();
const modalsStore = useModalsStore();

const showingNavigationDropdown = ref(false);

const sidebarMenuItems = computed(() => {
    return SidebarMenuItems.filter((menuItem) => {
        if (menuItem.auth === null) {
            return true;
        }

        return menuItem.auth === !!user.value;
    });
});

watch(showingNavigationDropdown, (showing) => {
    const bodyClassList = document.body.classList;

    showing
        ? bodyClassList.add('overflow-hidden')
        : bodyClassList.remove('overflow-hidden');
});

const closeNavigationDropdown = () => {
    showingNavigationDropdown.value = false;
};

const logout = () => {
    router.post(route('logout'));
};

const showHelpModal = () => {
    closeNavigationDropdown();
    modalsStore.showHelp();
};
</script>

<template>
    <nav
        :class="{
            'fixed h-screen w-full bg-white border-[#DEDEE3]': showingNavigationDropdown,
        }"
        class="bg-white border-b border-[#DEDEE3] transition-all px-6 z-10"
    >
        <div class="max-w-7xl mx-auto">
            <div class="flex justify-between h-16 md:justify-end">
                <div class="flex md:hidden">
                    <!-- Logo -->
                    <div class="shrink-0 flex items-center">
                        <Link :href="route(user ? 'dashboard' : 'home')">
                            <img src="/img/logo.svg" alt="" class="w-10 h-10" />
                        </Link>
                    </div>
                </div>

                <div class="hidden sm:flex sm:items-center sm:ms-6 sm:pr-6 lg:pr-8">
                    <!-- Settings Dropdown -->
                    <div v-if="user" class="ms-3 relative">
                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <span class="inline-flex rounded-md">
                                    <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-[#524E56] bg-white transition ease-in-out duration-150">
                                        <Avatar class="mr-3"/>
                                        {{ user.name }}
                                        <ChevronDownIcon class="h-4 w-4 ms-2 -me-0.5"/>
                                    </button>
                                </span>
                            </template>

                            <template #content>
                                <DropdownLink
                                    v-for="(item, key) in ProfileMenuItems"
                                    :key="key"
                                    :href="route(item.route) + item.routeHash ?? ''"
                                >
                                    <span class="flex items-center gap-3">
                                        <component :is="item.icon" class="w-4 h-4"/>
                                        {{ __(item.title) }}
                                    </span>
                                </DropdownLink>

                                <div class="border-t border-gray-200" />

                                <!-- Authentication -->
                                <form @submit.prevent="logout">
                                    <DropdownLink as="button">
                                        <span class="flex items-center gap-3">
                                            <ArrowRightStartOnRectangleIcon class="w-4 h-4"/>
                                            {{ __('cta.logout') }}
                                        </span>
                                    </DropdownLink>
                                </form>
                            </template>
                        </Dropdown>
                    </div>

                    <div v-else class="ms-3 relative">
                        <NavLink :href="route('login')">
                            <span class="flex items-center gap-3">
                                 <ArrowRightEndOnRectangleIcon class="w-5 h-5"/>
                                {{ __('page.login.nav_title') }}
                            </span>
                        </NavLink>
                    </div>
                </div>

                <!-- Hamburger -->
                <div class="-me-2 flex items-center sm:hidden">
                    <button class="inline-flex items-center justify-center p-2 rounded-md text-[#272529] transition duration-150 ease-in-out" @click="showingNavigationDropdown = ! showingNavigationDropdown">
                        <svg
                            class="h-6 w-6"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                            <path
                                :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="sm:hidden mt-8">
            <div class="flex flex-col gap-2">
                <ResponsiveNavLink
                    v-for="(item, key) in sidebarMenuItems"
                    :key="key"
                    :href="route(item.route)"
                    :active="route().current(item.route)"
                    @click="closeNavigationDropdown"
                >
                    <span class="flex items-center gap-3">
                        <component :is="item.icon" class="w-5 h-5"/>
                        {{ __(item.title) }}
                    </span>
                </ResponsiveNavLink>

                <ResponsiveNavLink
                    v-if="user"
                    href="#"
                    @click.prevent="showHelpModal"
                >
                    <span class="flex items-center gap-3">
                        <ChatBubbleLeftIcon class="w-5 h-5"/>
                        {{ __('Help') }}
                    </span>
                </ResponsiveNavLink>

                <ResponsiveNavLink
                    v-else
                    :href="route('login')"
                >
                    <span class="flex items-center gap-3">
                        <ArrowRightEndOnRectangleIcon class="w-5 h-5"/>
                        {{ __('page.login.nav_title') }}
                    </span>
                </ResponsiveNavLink>
            </div>

            <!-- Responsive Settings Options -->

            <div v-if="user" class="mt-6 pt-6 border-t border-gray-200">
                <div class="flex items-center">
                    <Avatar class="mr-3"/>

                    <div>
                        <div
                            class="font-medium text-base text-gray-800"
                            v-text="user.name"
                        />

                        <div
                            class="font-medium text-sm text-gray-500"
                            v-text="user.email"
                        />
                    </div>
                </div>

                <div class="mt-4 space-y-2">
                    <ResponsiveNavLink
                        v-for="(item, key) in ProfileMenuItems"
                        :key="key"
                        :href="route(item.route) + item.routeHash ?? ''"
                        @click="closeNavigationDropdown"
                    >
                        <span class="flex items-center gap-3">
                            <component :is="item.icon" class="w-5 h-5"/>
                            {{ __(item.title) }}
                        </span>
                    </ResponsiveNavLink>

                    <!-- Authentication -->
                    <form method="POST" @submit.prevent="logout">
                        <ResponsiveNavLink as="button">
                            <span class="flex items-center gap-3">
                                <ArrowRightStartOnRectangleIcon class="w-5 h-5"/>
                                {{ __('cta.logout') }}
                            </span>
                        </ResponsiveNavLink>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</template>

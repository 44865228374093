<script setup>
import { computed } from 'vue';
import LessonsListItem from './LessonsListItem.vue';
import LessonsListLocked from './LessonsListLocked.vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        required: null,
    },
});

const lessons = computed(() => {
    if (props.course.lessons.length === 0) {
        return [];
    }

    if (!props.order) {
        return props.course.lessons.slice(0, 3);
    }

    return props.course.lessons;
});
</script>

<template>
    <div>
        <h2
            class="text-lg font-semibold text-[#272529] mt-8"
            v-text="__('text.course')"
        />

        <div class="relative">
            <div
                :style="{
                    'mask-image': order ? null : 'linear-gradient(#000, transparent)',
                }"
                class="flex flex-col gap-2 mt-4"
            >
                <LessonsListItem
                    v-for="lesson in lessons"
                    :key="lesson.id"
                    :course="course"
                    :lesson="lesson"
                    :order="order"
                />
            </div>

            <LessonsListLocked
                :show="!order"
                :course="course"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    active: Boolean,
    href: String,
    as: String,
});

const classes = computed(() => {
    return props.active
        ? 'bg-[#F0F0F2]'
        : '';
});
</script>

<template>
    <div>
        <button v-if="as == 'button'" :class="classes" class="block w-full font-medium text-[#524E56] text-start rounded-lg px-3 py-2.5">
            <slot />
        </button>

        <Link v-else :href="href" :class="classes" class="block w-full font-medium text-[#524E56] text-start rounded-lg px-3 py-2.5">
            <slot />
        </Link>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { ArrowDownTrayIcon } from '@heroicons/vue/24/solid';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    course: Object,
    lesson: Object,
});

const lessonIndex = computed(() => {
    return props.course.lessons
        .map((lesson) => lesson.id)
        .indexOf(props.lesson.id) ;
});


const lessonNumber = computed(() => {
    return lessonIndex.value + 1;
});

const previousLesson = computed(() => {
    if (lessonIndex.value !== 0) {
        return props.course.lessons[lessonIndex.value - 1]
    }

    return null
});

const nextLesson = computed(() => {
    if (lessonIndex.value + 1 !== props.course.length) {
        return props.course.lessons[lessonIndex.value + 1]
    }

    return null
});
</script>

<template>
    <div class="p-6">
        <h1
            class="text-lg font-medium text-[#272529]"
            v-text="`${lessonNumber}. ${lesson.title}`"
        />

        <p
            v-if="lesson.content"
            class="text-[#524E56] mt-4"
            v-html="lesson.content"
        />

        <div
            v-if="Object.keys(lesson.files).length > 0"
            class="flex flex-col items-start gap-2 mt-4 md:mt-6"
        >
            <a
                v-for="(name, id) in lesson.files"
                :key="id"
                :href="route('courses.files.download', {
                    course: course.slug,
                    media: id,
                })"
                class="w-full flex items-center justify-center gap-2.5 bg-white text-xs font-medium text-[#524E56] border border-[#DEDEE3] rounded-lg z-10 px-3 py-2.5 md:text-sm md:px-4 md:py-3 md:w-auto"
            >
                <ArrowDownTrayIcon class="h-4 w-4 md:h-5 md:w-5"/>
                <span class="w-full" v-text="name"/>
            </a>
        </div>

        <div
            v-if="previousLesson || nextLesson"
            :class="{
                'justify-start': previousLesson && !nextLesson,
                'justify-between': previousLesson && nextLesson,
                'justify-end': !previousLesson && nextLesson,
            }"
            class="flex items-center mt-5"
        >
            <Link
                v-if="previousLesson"
                :href="route('courses.lessons.watch', {
                    course: course.slug,
                    lesson: previousLesson.slug,
                })"
                class="border border-[#DEDEE3] rounded-lg px-4 py-3"
            >
                <ArrowLeftIcon class="h-5 w-5 text-[#524E56]"/>
            </Link>

            <Link
                v-if="nextLesson"
                :href="route('courses.lessons.watch', {
                    course: course.slug,
                    lesson: nextLesson.slug,
                })"
                class="border border-[#DEDEE3] rounded-lg px-4 py-3"
            >
                <ArrowRightIcon class="h-5 w-5 text-[#524E56]"/>
            </Link>
        </div>
    </div>
</template>

export function useString() {
    const truncate = (text, limit = 150) => {
        if (text.length <= limit) {
            return text
        }

        for (let i = limit; i > 0; i--) {
            if (
                text.charAt(i) === ' ' &&
                (text.charAt(i - 1) !== ',' ||
                    text.charAt(i - 1) !== '.' ||
                    text.charAt(i - 1) !== ';')
            ) {
                return text.substring(0, i) + '...'
            }
        }
    };

    return { truncate };
}

<script setup>
import { onMounted, ref } from 'vue';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import AnimatedComponent from '@/Components/AnimatedComponent.vue';

defineProps({
    services: {
        type: Array,
        required: true,
    },
});

const activeServiceKey = ref(null);
const serviceContent = ref(null);

onMounted(() => {
    if (serviceContent.value) {
        serviceContent.value.forEach((item) => {
            const ulClassList = item.querySelector('ul')?.classList;
            const olClassList = item.querySelector('ol')?.classList;

            if (ulClassList) {
                ulClassList.add('list-disc');
                ulClassList.add('pl-4');
            }

            if (olClassList) {
                olClassList.add('list-decimal');
                olClassList.add('pl-4');
            }
        })
    }
});

const toggleService = (key) => {
    activeServiceKey.value = activeServiceKey.value === key
        ? null
        : activeServiceKey.value = key;
};
</script>

<template>
    <section class="px-6 py-12 md:py-17">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-16 md:grid-cols-3">
                <div class="col-span-1">
                    <h2
                        class="text-3xl font-semibold text-[#272529]"
                        v-text="__('page.home.services.title')"
                    />

                    <p
                        class="text-[#524E56] mt-6"
                        v-html="__('page.home.services.text')"
                    />

                    <AnimatedComponent>
                        <img src="/img/landing/services.png" alt="" class="mt-6"/>
                    </AnimatedComponent>
                </div>

                <div class="col-span-2">
                    <div
                        v-for="(service, key) in services"
                        :key="service.id"
                        @click="toggleService(key)"
                    >
                        <h3>
                            <button
                                :class="{
                                    'border-t': key === 0,
                                    'border-b-transparent': activeServiceKey === key,
                                    'border-[#DEDEE3]': activeServiceKey !== key,
                                }"
                                type="button"
                                class="flex items-center justify-between gap-4 w-full text-[#272529] border-b py-6.5"
                            >
                                <span class="text-xl font-medium text-left" v-text="service.title"/>

                                <ChevronUpIcon v-if="activeServiceKey === key" class="h-8 w-8 text-[#0F172A]"/>
                                <ChevronDownIcon v-else class="h-8 w-8 text-[#0F172A]"/>
                            </button>
                        </h3>

                        <AnimatedComponent v-show="activeServiceKey === key" :infinity="true">
                            <div
                                ref="serviceContent"
                                class="pb-6.5 text-[#524E56] border-b border-gray-200 mb-2"
                                v-html="service.content"
                            />
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

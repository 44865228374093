<script setup>
import { useString } from '@/Composables/useString.js';

const { truncate } = useString();

defineProps({
    course: {
        type: Object,
        required: true,
    },
    coupon: {
        type: Object,
        default: () => ({}),
    },
    amount: {
        type: String,
        required: true,
    },
});
</script>

<template>
    <div class="flex flex-col bg-white rounded-lg p-6">
        <h2
            class="text-lg font-semibold text-[#272529]"
            v-text="__('text.course')"
        />

        <div class="flex flex-row gap-4 mt-6">
            <img :src="course.image_url" alt="" class="h-12 w-12 rounded-lg" />

            <div class="flex flex-col gap-2 text-sm">
                <h3 class="text-[#272529]" v-text="course.title"/>

                <p
                    v-if="course.content"
                    class="font-semibold text-[#524E56]"
                    v-html="truncate(course.content, 100)"
                />
            </div>
        </div>

        <div class="block h-px bg-[#DEDEE3] mt-4"/>

        <div class="flex flex-col gap-2 text-sm mt-2">
            <div class="flex justify-between text-[#524E56]">
                <div v-text="__('text.course_price')"/>
                <div>{{ course.price }} &euro;</div>
            </div>

            <div v-if="coupon" class="flex justify-between text-[#524E56]">
                <div v-text="coupon.code"/>
                <div>{{ coupon.discount_size }} %</div>
            </div>

            <div class="flex justify-between font-semibold text-[#272529]">
                <div v-text="__('text.total_price')"/>
                <div>{{ amount }} &euro;</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { LockClosedIcon } from '@heroicons/vue/24/solid';
import { useUser } from '@/Composables/useUser.js';
import Layout from '@/Layouts/CheckoutLayout.vue';
import Checkbox from '@/Components/Form/Checkbox.vue';
import AcceptTermsCheckbox from '@/Components/Form/AcceptTermsCheckbox.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import CheckoutItem from './Partials/CheckoutItem.vue';
import PaymentChannelSelect from './Partials/PaymentChannelSelect.vue';
import CouponCodeInput from './Partials/CouponCodeInput.vue';

const user = useUser();

defineOptions({
    layout: Layout,
});

const form = useForm({
    full_name: '',
    email: user.value?.email ?? '',
    is_company: false,
    company_name: '',
    company_code: '',
    company_address: '',
    company_vat_code: '',
    payment_provider: 'opay',
    payment_channel: '',
    coupon_id: null,
    terms: false,
});

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    payment_channels: {
        type: Array,
        default: () => ([]),
    },
    last_transaction: {
        type: Object,
        default: null,
    },
});

const coupon = ref(null);
const amount = ref(props.course.price);

onMounted(() => {
    const transaction = props.last_transaction;

    if (transaction) {
        form.full_name = transaction.full_name;
        form.email = transaction.email;

        if (transaction.company_name) {
            form.is_company = true;
            form.company_name = transaction.company_name;
            form.company_code = transaction.company_code;
            form.company_address = transaction.company_address;
            form.company_vat_code = transaction.company_vat_code;
        }
    }
});

const handleCalculate = (data) => {
    coupon.value = data.coupon;
    amount.value = data.amount;
};

const handleForm = () => {
    form.post(route('checkout.pay', props.course));
};
</script>

<template>
    <MetaHead :title="__('page.checkout.meta_title')"/>

    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <h1
            class="text-2xl font-semibold text-[#272529]"
            v-text="__('page.checkout.title')"
        />

        <div class="grid grid-cols-1 gap-6 mt-6 md:grid-cols-6">
            <div class="order-2 md:col-span-4 md:order-1">
                <div class="flex flex-col bg-white rounded-lg">
                    <form @submit.prevent="handleForm">
                        <div class="p-6">
                            <h2 class="text-lg font-semibold text-[#272529]">
                                1. {{ __('text.information') }}
                            </h2>

                            <div class="grid gap-4 mt-6 md:grid-cols-2">
                                <div>
                                    <InputLabel :value="__('label.full_name')" for="full_name" />
                                    <TextInput
                                        id="full_name"
                                        v-model="form.full_name"
                                        type="text"
                                        class="mt-1 block w-full"
                                        required
                                        autofocus
                                        autocomplete="full_name"
                                    />
                                    <InputError class="mt-2" :message="form.errors.full_name" />
                                </div>

                                <div>
                                    <InputLabel :value="__('label.email')" for="email" />
                                    <TextInput
                                        id="email"
                                        v-model="form.email"
                                        type="email"
                                        class="mt-1 block w-full"
                                        required
                                        autocomplete="email"
                                    />
                                    <InputError class="mt-2" :message="form.errors.email" />
                                </div>

                                <div class="col-span-full">
                                    <InputLabel for="is_company">
                                        <div class="flex items-center">
                                            <Checkbox id="is_company" v-model:checked="form.is_company" name="is_company" />
                                            <div class="ms-2" v-html="__('label.is_company')"/>
                                        </div>
                                        <InputError class="mt-2" :message="form.errors.is_company" />
                                    </InputLabel>
                                </div>

                                <template v-if="form.is_company">
                                    <div>
                                        <InputLabel :value="__('label.company_name')" for="company_name" />
                                        <TextInput
                                            id="company_name"
                                            v-model="form.company_name"
                                            type="text"
                                            class="mt-1 block w-full"
                                            autocomplete="company_name"
                                        />
                                        <InputError class="mt-2" :message="form.errors.company_name" />
                                    </div>

                                    <div>
                                        <InputLabel :value="__('label.company_code')" for="company_code" />
                                        <TextInput
                                            id="company_code"
                                            v-model="form.company_code"
                                            type="text"
                                            class="mt-1 block w-full"
                                            autocomplete="company_code"
                                        />
                                        <InputError class="mt-2" :message="form.errors.company_code" />
                                    </div>

                                    <div>
                                        <InputLabel :value="__('label.company_address')" for="company_address" />
                                        <TextInput
                                            id="company_address"
                                            v-model="form.company_address"
                                            type="text"
                                            class="mt-1 block w-full"
                                            autocomplete="company_address"
                                        />
                                        <InputError class="mt-2" :message="form.errors.company_address" />
                                    </div>

                                    <div>
                                        <InputLabel :value="__('label.company_vat_code')" for="company_vat_code" />
                                        <TextInput
                                            id="company_vat_code"
                                            v-model="form.company_vat_code"
                                            type="text"
                                            class="mt-1 block w-full"
                                            autocomplete="company_vat_code"
                                        />
                                        <InputError class="mt-2" :message="form.errors.company_vat_code" />
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="block h-px bg-[#DEDEE3]"/>

                        <div class="flex flex-col gap-6 p-6">
                            <h2 class="text-lg font-semibold text-[#272529]">
                                2. {{ __('text.payment') }}
                            </h2>

                            <div>
                                <InputLabel :value="__('label.payment_type')" for="payment_channel" />
                                <PaymentChannelSelect
                                    id="payment_channel"
                                    v-model="form.payment_channel"
                                    :channels="payment_channels"
                                    class="mt-1"
                                />
                                <InputError class="mt-2" :message="form.errors.payment_channel" />
                            </div>

                            <CouponCodeInput
                                v-model="form.coupon_id"
                                :course="course"
                                @calculated="handleCalculate"
                                @processing="(processing) => form.processing = processing"
                            />

                            <InputLabel for="terms">
                                <div class="flex items-center">
                                    <AcceptTermsCheckbox id="terms" v-model:checked="form.terms" name="terms" required />
                                </div>
                                <InputError class="mt-2" :message="form.errors.terms" />
                            </InputLabel>

                            <div class="flex flex-col items-center gap-6 md:flex-row">
                                <PrimaryButton
                                    :class="{ 'opacity-25': form.processing }"
                                    :disabled="form.processing"
                                    class="w-full md:w-auto"
                                >
                                    {{ __('cta.pay') }} {{ course.price }} &euro;
                                </PrimaryButton>

                                <div class="flex items-center gap-2 text-sm text-[#524E56]">
                                    <LockClosedIcon class="h-5 w-5"/>
                                    {{ __('text.secure_payment')}}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="order-1 md:col-span-2 md:order-2">
                <CheckoutItem
                    :course="course"
                    :coupon="coupon"
                    :amount="amount"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    modelValue: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue', 'calculated', 'processing']);

const form = useForm({
    code: '',
});

const showInput = ref(false);

const handleApply = async () => {
    setFormProcessing(true);
    form.clearErrors('code');

    await window.axios.post(route('api::checkout.calculate', props.course), {
        code: form.code,
    })
        .then((resp) => {
            const data = resp.data;

            if (data.coupon) {
                emit('update:modelValue', data.coupon.id);

                emit('calculated', {
                    amount: data.amount,
                    coupon: data.coupon,
                });

                return;
            }

            resetForm();
        })
        .catch(() => resetForm());

    setFormProcessing(false);
};

const setFormProcessing = (processing) => {
    form.processing = processing;
    emit('processing', processing);
};

const resetForm = () => {
    emit('update:modelValue', null);

    emit('calculated', {
        amount: props.course.price,
        coupon: null,
    });

    form.reset('code');
    form.setError('code', 'Incorrect discount code');
};
</script>

<template>
    <div>
        <div
            class="w-full flex items-center gap-2 hover:cursor-pointer"
            @click="showInput = !showInput"
        >
            <InputLabel
                :value="__('label.discount_code')"
                for="coupon_code"
                class="hover:cursor-pointer"
            />

            <ChevronUpIcon v-if="showInput" class="h-4 w-4 text-[#524E56]"/>
            <ChevronDownIcon v-else class="h-4 w-4 text-[#524E56]"/>
        </div>

        <form
            v-if="showInput"
            class="flex flex-col items-center gap-2 mt-1 md:flex-row"
            @submit.prevent="handleApply"
        >
            <TextInput
                id="coupon_code"
                v-model="form.code"
                :placeholder="__('placeholder.discount_code')"
                type="text"
                class="block w-full md:w-1/2"
                autocomplete="off"
            />

            <PrimaryButton
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                class="w-full md:w-auto"
            >
                {{ __('cta.apply') }}
            </PrimaryButton>
        </form>

        <InputError class="mt-2" :message="form.errors.code ? __(form.errors.code) : null" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { PlayCircleIcon } from '@heroicons/vue/24/outline';
import TrailerModal from './TrailerModal.vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        default: null,
    },
});

const showTrailerModal = ref(false);

const openTrailerModal = () => {
    if (props.course.intro_video_url) {
        showTrailerModal.value = true;
    }
};

const closeTrailerModal = () => {
    showTrailerModal.value = false;
};
</script>

<template>
    <div
        class="relative w-full hover:cursor-pointer"
        @click="openTrailerModal"
    >
        <div
            :class="{
                'after:content-[\'\'] after:absolute after:inline-block after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-b after:from-black after:to-black after:opacity-60': !!course.intro_video_url,
            }"
            class="relative h-full"
        >
            <img :src="course.image_url" alt="" class="h-full w-full md:object-cover" />
        </div>

        <div v-if="!!course.intro_video_url" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <PlayCircleIcon class="h-16 w-16 text-white"/>
        </div>

        <TrailerModal
            :show="showTrailerModal"
            :course="course"
            :order="order"
            @close="closeTrailerModal"
        />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { Head } from '@inertiajs/vue3';
import BlankLayout from '@/Layouts/BlankLayout.vue';

defineOptions({
    layout: BlankLayout,
});

const props = defineProps({
    status: Number,
    translations: Object
});

const title = computed(() => props.translations.errors[props.status].title);
const description = computed(() => props.translations.errors[props.status].description);

document.body.style.overflow = 'hidden';
</script>

<template>
    <Head :title="title"/>

    <div class="error-page">
        <div class="md:flex min-h-screen">
            <div
                class="w-full md:w-1/2 bg-white flex items-center justify-center"
            >
                <div class="max-w-lg m-12 md:m-8">
                    <div
                        class="text-black text-4xl md:text-7xl font-black"
                        v-text="title"
                    />

                    <div class="w-16 h-1 bg-purple-light my-3 md:my-6" />

                    <p
                        class="text-grey-darker text-2xl md:text-3xl font-light mb-10 leading-normal"
                        v-text="description"
                    />

                    <Link
                        :href="route('home')"
                        class="bg-blue-600 text-white border border-transparent font-bold uppercase tracking-wide py-3 px-8 rounded-md hover:bg-white hover:text-blue-600 hover:border-blue-600 transition"
                    >
                        Atgal
                    </Link>
                </div>
            </div>

            <div
                class="relative pb-full md:flex md:pb-0 md:min-h-screen w-full md:w-1/2"
            >
                <div
                    style="background-image: url('/img/error.svg')"
                    class="absolute w-full h-screen pin bg-cover bg-no-repeat md:bg-left lg:bg-center"
                />
            </div>
        </div>
    </div>
</template>

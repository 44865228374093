<script setup>
import { computed, ref, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';
import { useUser } from '@/Composables/useUser.js';
import Tabs from '@/Components/Tabs.vue';
import UpdatePasswordForm from '@/Pages/Profile/Partials/UpdatePasswordForm.vue';
import UpdateProfileInformationForm from '@/Pages/Profile/Partials/UpdateProfileInformationForm.vue';

const user = useUser();
const selectedTab = ref('profile');

const props = defineProps({
    accepted_marketing: Boolean,
});

const tabs = ref([
    {
        id: 'profile',
        title: 'page.profile.meta_title',
        content: UpdateProfileInformationForm,
    },
    {
        id: 'password',
        title: 'page.password_change.meta_title',
        content: UpdatePasswordForm,
    },
]);

const selectedTabItem = computed(() => tabs.value.find((tab) => tab.id === selectedTab.value));

onMounted(() => {
    selectedTab.value = tabs.value.find((tab) => router.page.url.endsWith(`#${tab.id}`))?.id ?? 'profile';
});
</script>

<template>
    <MetaHead :title="__(selectedTabItem.title)"/>

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529]"
            v-text="__('page.profile.title')"
        />

        <Tabs v-model="selectedTab" :tabs="tabs" class="mt-8">
            <UpdateProfileInformationForm
                v-show="selectedTab === 'profile'"
                :user="user"
                :accepted_marketing="accepted_marketing"
            />

            <UpdatePasswordForm v-show="selectedTab === 'password'"/>
        </Tabs>
    </div>
</template>

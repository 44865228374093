<script setup>
import { useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/AuthLayout.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import AcceptTermsCheckbox from '@/Components/Form/AcceptTermsCheckbox.vue';

defineOptions({
    layout: Layout,
});

const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    courses: {
        type: Array,
        default: () => ([]),
    }
});

const form = useForm({
    name: '',
    email: props.email,
    password: '',
    password_confirmation: '',
    accepted_marketing: true,
    terms: false,
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <MetaHead :title="__('page.register_complete.meta_title')" />

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529] text-center mb-8"
            v-text="__('page.register_complete.title')"
        />

        <div
            v-if="courses.length > 0"
            class="flex flex-col bg-blue-100 border border-[#254352] rounded-lg p-2 mb-8"
        >
            <div v-text="__('text.you_will_get_for_free_this_courses')"/>

            <ul>
                <li
                    v-for="course in courses"
                    :key="course.id"
                    class="text-sm"
                >
                    {{ course.title }} <span class="text-[#524E56] line-through">{{ course.regular_price }}&euro;</span>
                </li>
            </ul>
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel :value="__('label.username')" for="name" />
                <TextInput
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="name"
                />
                <InputError class="mt-2" :message="form.errors.name" />
            </div>

            <div class="mt-4">
                <InputLabel :value="__('label.email')" for="email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    :disabled="true"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autocomplete="email"
                />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel :value="__('label.password')" for="password" />
                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="new-password"
                />
                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="mt-4">
                <InputLabel :value="__('label.confirm_password')" for="password_confirmation" />
                <TextInput
                    id="password_confirmation"
                    v-model="form.password_confirmation"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="new-password"
                />
                <InputError class="mt-2" :message="form.errors.password_confirmation" />
            </div>

            <div class="mt-4">
                <InputLabel for="terms">
                    <AcceptTermsCheckbox id="terms" v-model:checked="form.terms" name="terms" required />
                    <InputError class="mt-2" :message="form.errors.terms" />
                </InputLabel>
            </div>

            <PrimaryButton
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                class="w-full mt-8"
                v-text="__('cta.register')"
            />
        </form>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
    <div class="flex items-center">
        <input
            v-model="proxyChecked"
            type="checkbox"
            :value="value"
            class="rounded border-[#DEDEE3] text-[#E89F8D] shadow-sm focus:ring-[#E89F8D]"
        >

        <div
            class="ms-2"
            v-text="__('label.agree_with_marketing')"
        />
    </div>
</template>

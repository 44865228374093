<script setup>
import { useForm } from '@inertiajs/vue3';
import ActionMessage from '@/Components/ActionMessage.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import AcceptMarketingCheckbox from '@/Components/Form/AcceptMarketingCheckbox.vue';

const props = defineProps({
    user: Object,
    accepted_marketing: Boolean,
});

const form = useForm({
    _method: 'PUT',
    name: props.user.name,
    email: props.user.email,
    accepted_marketing: props.accepted_marketing,
});

const handleUpdate = () => {
    form.post(route('user-profile-information.update'), {
        errorBag: 'updateProfileInformation',
        preserveScroll: true,
    });
};
</script>

<template>
    <div class="bg-white rounded-lg p-6">
        <form class="max-w-md flex flex-col gap-4" @submit.prevent="handleUpdate">
            <!-- Name -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.username')" for="name" value="Name" />
                <TextInput
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="mt-1 block w-full"
                    required
                    autocomplete="name"
                />
                <InputError :message="form.errors.name" class="mt-2" />
            </div>

            <!-- Email -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.email')" for="email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autocomplete="username"
                />
                <InputError :message="form.errors.email" class="mt-2" />
            </div>

            <!-- Marketing -->
            <div class="col-span-6">
                <InputLabel for="accepted_marketing">
                    <AcceptMarketingCheckbox id="accepted_marketing" v-model:checked="form.accepted_marketing" name="marketing" />
                    <InputError class="mt-2" :message="form.errors.accepted_marketing" />
                </InputLabel>
            </div>

            <div class="flex items-center gap-4">
                <PrimaryButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    v-text="__('cta.update')"
                />

                <ActionMessage :on="form.recentlySuccessful" class="me-3">
                    {{ __('flash_success.saved') }}
                </ActionMessage>
            </div>
        </form>
    </div>
</template>

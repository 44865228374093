<script setup>
import { useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/AuthLayout.vue'
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';

defineOptions({
    layout: Layout,
});

defineProps({
    status: String,
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
</script>

<template>
    <MetaHead :title="__('page.forgot_password.meta_title')" />

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529] text-center mb-4"
            v-text="__('page.forgot_password.title')"
        />

        <p
            class="mb-8 text-sm text-gray-600 text-center"
            v-text="__('page.forgot_password.text')"
        />

        <div
            v-if="status"
            class="mb-4 font-medium text-sm text-green-600"
            v-text="status"
        />

        <form @submit.prevent="submit">
            <div>
                <InputLabel :value="__('label.email')" for="email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="email"
                />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <PrimaryButton
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                class="w-full mt-8"
                v-text="__('cta.send_password_reset_link')"
            />
        </form>
    </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue']);

defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    channels: {
        type: Array,
        default: () => ([]),
    },
});

const handleSelect = (val) => emit('update:modelValue', val);
</script>

<template>
    <div class="grid grid-cols-1 gap-2 md:grid-cols-5">
        <div
            v-for="channel in channels"
            :key="channel.name"
            :class="{
                'border-[#E89F8D] shadow': channel.name === modelValue,
                'border-[#DEDEE3]': channel.name !== modelValue,
            }"
            class="flex items-center justify-center border border-[#DEDEE3] rounded-lg p-4 hover:cursor-pointer"
            @click.prevent="handleSelect(channel.name)"
        >
            <img :src="channel.logo_url" :alt="channel.title" class="max-h-[24px]"/>
        </div>
    </div>
</template>

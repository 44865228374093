<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const PING_TIMEOUT = 5000;

export default {
    props: {
        course: {
            type: Object,
            required: true,
        },
        lesson: {
            type: Object,
            required: true,
        },
        playback: {
            type: Object,
            required: true,
        },
        src: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            tracker: null,
            player: null,
            options: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: this.src,
                        type: 'video/mp4',
                    },
                ],
            },
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            let currentTime = 0;

            if (this.playback?.current_time) {
                currentTime = Math.floor(this.playback.current_time * 100 / this.lesson.runtime) > 95
                    ? 0
                    : this.playback.current_time;
            }

            this.player.currentTime(currentTime);
        });

        this.player.on('play', () => this.startTrack());
        this.player.on('pause', () => this.stopTrack());
        this.player.on('ended', () => this.stopTrack());
        this.player.on('ended', () => this.ping());
    },
    beforeDestroy() {
        this.stopTrack();

        if (this.player) {
            this.player.dispose();
        }
    },
    methods: {
        startTrack() {
            if (!this.tracker) {
                this.tracker = setInterval(() => {
                    this.ping();
                }, PING_TIMEOUT);
            }
        },
        stopTrack() {
            clearInterval(this.tracker);
            this.ping();

            this.tracker = null;
        },
        async ping() {
            const currentTime = this.player.currentTime();

            if (currentTime === 0) {
                return;
            }

            await window.axios.put(route('api::playback.ping', this.playback.id), {
                time: currentTime,
            }).catch((e) => {
                if (e.response.status !== 200) {
                    window.location.href = route('courses.show', this.course.slug);
                }
            })
        },
    },
};
</script>

<template>
    <video ref="videoPlayer" class="video-js vjs-16-9"/>
</template>

<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';
import ActionMessage from '@/Components/ActionMessage.vue';

const form = useForm({
    email: '',
    tags: ['source:landing'],
    status: 1,
});

const formSucceed = ref(false);

const handleForm = async () => {
    if (form.processing) return;

    form.clearErrors();
    form.processing = true;

    await window.axios.post(route('api::newsletter.subscribe'), form.data())
        .then(() => {
            form.reset();
            formSucceed.value = true;

            const interval = setInterval(() => {
                formSucceed.value = false;
                clearInterval(interval);
            }, 3000);
        })
        .catch((e) => {
            const response = e.response;

            if (response.status === 422) {
                Object.keys(response.data.errors).map((property) => {
                    form.setError(property, response.data.errors[property][0]);
                });
            }
        })
        .finally(() => {
            form.processing = false;
        });
};
</script>

<template>
    <section class="relative bg-[#272529] overflow-hidden px-6 py-12 md:py-17">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-6 md:grid-cols-2">
                <div class="z-10">
                    <h2 class="text-3xl font-semibold text-white">
                        Aukite su mūsų naujienlaiškiu!
                    </h2>

                    <form
                        class="mt-8"
                        @submit.prevent="handleForm"
                    >
                        <div>
                            <InputLabel
                                :value="__('label.email')"
                                for="email"
                                class="text-white"
                            />

                            <TextInput
                                id="email"
                                v-model="form.email"
                                :placeholder="__('placeholder.enter_email')"
                                type="email"
                                class="mt-1.5 block w-full md:w-3/5"
                                required
                                autocomplete="email"
                            />

                            <InputError
                                class="mt-2"
                                :message="form.errors.email"
                            />
                        </div>

                        <div
                            class="text-sm text-white mt-1.5"
                            v-text="__('text.by_subscribing_newsletter_you_agree_with_terms')"
                        />

                        <div class="flex flex-col items-center gap-4 mt-8 md:flex-row">
                            <SecondaryButton
                                :disabled="form.processing"
                                class="w-full md:w-auto"
                                v-text="__('cta.subscribe')"
                            />

                            <ActionMessage :on="formSucceed" class="me-3">
                                <span class="text-white" v-text="__('flash_success.newsletter_subscribed')"/>
                            </ActionMessage>
                        </div>
                    </form>
                </div>

                <div class="hidden justify-end z-10 md:flex">
                    <img src="/img/landing/newsletter.png" alt=""/>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 -right-[50px] h-[40px] w-full bg-[#E89F8D] -rotate-[15deg] md:w-3/4"/>
    </section>
</template>

const items = [
    {
        title: 'page.home.about.nav_title',
        route: 'home',
        hash: '#about',
    },
    {
        title: 'page.home.courses.nav_title',
        route: 'home',
        hash: '#courses',
    },
    {
        title: 'page.home.services.nav_title',
        route: 'home',
        hash: '#services',
    },
    {
        title: 'page.home.reviews.nav_title',
        route: 'home',
        hash: '#reviews',
    },
    {
        title: 'page.articles.nav_title',
        route: 'articles.index',
        hash: null,
    },
    {
        title: 'page.home.team.nav_title',
        route: 'home',
        hash: '#team',
    },
    {
        title: 'text.contacts',
        route: 'home',
        hash: '#contacts',
    },
];

export default items;

<template>
    <header class="relative bg-[#272529] overflow-hidden pt-[171px] px-6 pb-20">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-12 md:grid-cols-2">
                <div class="flex flex-col justify-center">
                    <h1
                        class="text-5xl text-white"
                        v-text="__('page.articles.title')"
                    />

                    <p class="text-lg text-[#DEDEE3] mt-4" v-text="__('page.articles.text')"/>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 -right-[50px] h-[40px] w-full bg-[#E89F8D] -rotate-[15deg] md:w-3/4"/>
    </header>
</template>

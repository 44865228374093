<script setup>
import { computed } from 'vue';

const props = defineProps({
    href: String,
    active: Boolean,
});

const classes = computed(() => {
    return props.active
        ? 'inline-flex items-center px-1 pt-1 font-medium leading-5 text-[#272529] transition duration-150 ease-in-out'
        : 'inline-flex items-center px-1 pt-1 font-medium leading-5 text-[#272529] transition duration-150 ease-in-out';
});
</script>

<template>
    <Link :href="href" :class="classes">
        <slot />
    </Link>
</template>

import { HomeIcon, BookOpenIcon, AcademicCapIcon } from '@heroicons/vue/24/outline';

const items = [
    {
        title: 'page.dashboard.nav_title',
        icon: HomeIcon,
        route: 'home',
        auth: false,
    },
    {
        title: 'page.dashboard.nav_title',
        icon: HomeIcon,
        route: 'dashboard',
        auth: true,
    },
    {
        title: 'page.orders.nav_title',
        icon: BookOpenIcon,
        route: 'orders.index',
        auth: true,
    },
    {
        title: 'page.courses.nav_title',
        icon: AcademicCapIcon,
        route: 'courses.index',
        auth: null,
    },
];

export default items;

<script setup>
const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    tabs: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const changeTab = (id) => {
    emit('update:modelValue', id);
    window.location.hash = id
};

const hasBadge = (tab) => {
    if (Object.prototype.hasOwnProperty.call(tab, 'badge')) {
        return !!tab.badge;
    }

    return false;
};

const isTranslatable = (tab) => {
    if (Object.prototype.hasOwnProperty.call(tab, 'translate')) {
        return tab.translate;
    }

    return true;
};
</script>

<template>
    <div>
        <div class="text-center border-b border-[#DEDEE3]">
            <ul class="flex overflow-x-scroll -mb-px md:overflow-x-auto">
                <li v-for="item in tabs" :key="item.id" class="me-2">
                    <a
                        href="#"
                        :class="{
                            'text-[#272529] border-b-[#272529]': item.id === modelValue,
                            'text-[#524E56]': item.id !== modelValue,
                        }"
                        class="flex items-center gap-2.5 font-semibold border-b-[3px] border-transparent whitespace-nowrap px-6 pb-3"
                        @click.prevent="changeTab(item.id)"
                    >
                        {{ isTranslatable(item) ? __(item.title) : item.title }}
                        <div
                            v-if="hasBadge(item)"
                            class="flex items-center justify-center h-6 w-6 bg-[#E89F8D] text-sm rounded-full"
                            v-text="item.badge"
                        />
                    </a>
                </li>
            </ul>
        </div>

        <div class="mt-5">
            <slot/>
        </div>
    </div>
</template>

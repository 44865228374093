<script setup>
import { useString } from '@/Composables/useString.js';

const { truncate } = useString();

defineProps({
    article: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <div class="bg-[#F2F7F8] rounded-lg hover:cursor-pointer hover:drop-shadow-md">
        <Link :href="route('articles.show', article)">
            <img
                :src="article.image_url || 'https://placehold.co/350x162'"
                :alt="article.title"
                class="w-full"
            />

            <div class="p-6">
                <h2
                    class="font-semibold text-[#272529]"
                    v-text="article.title"
                />

                <p
                    v-if="article.excerpt"
                    class="text-sm text-[#524E56] mt-2.5"
                    v-text="truncate(article.excerpt, 120)"
                />
            </div>
        </Link>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useUser } from '@/Composables/useUser.js';

const user = useUser();

defineProps({
    course: Object,
});

const logout = () => {
    router.post(route('logout'));
};
</script>

<template>
    <nav class="bg-white border-b border-[#DEDEE3]">
        <div class="max-w-7xl mx-auto px-6">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <!-- Logo -->
                    <div class="flex items-center">
                        <Link :href="route('dashboard')">
                            <img src="/img/logo.svg" alt="" class="w-10 h-10" />
                        </Link>

                        <div class="h-1/2 w-px bg-[#DEDEE3] mx-6"/>

                        <Link
                            :href="route('courses.show', course.slug)"
                            class="text-[#272529]"
                        >
                            {{ course.title }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

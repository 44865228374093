<script setup>
import Modal from '@/Components/Modal.vue';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    course: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['close']);

const close = () => emit('close');
</script>

<template>
    <Modal
        :title="course.title"
        :show="show"
        max-width="5xl"
        @close="close"
    >
        <div class="flex -mx-6 -my-2">
            <video
                class="w-full"
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
            >
                <source :src="course.intro_video_url" type="video/mp4">
            </video>
        </div>

        <div class="flex flex-row justify-end gap-3 pt-6 text-end">
            <SecondaryButton
                type="button"
                class="w-full md:w-auto"
                v-text="__('cta.close')"
                @click.prevent="close"
            />

            <PrimaryLink
                v-if="!order"
                class="w-full whitespace-nowrap md:w-auto"
                :href="route('checkout', course)"
                v-html="__('cta.order_course_price', { price: course.price })"
            />
        </div>
    </Modal>
</template>

export default {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            const keys = key.indexOf(' ') >= 0 ? [key] : key.split('.');
            let translation = this.$page.props.translations;

            keys.forEach(function (keyTmp) {
                translation = translation[keyTmp] ? translation[keyTmp] : key;
            });

            Object.keys(replace).forEach(function (key) {
                translation = translation.replaceAll(':' + key, replace[key]);
            });

            return translation;
        },

        /**
         * Translate the given key with basic pluralization.
         *
         * http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
         */
        __n(key, number, replace = {}) {
            const options = this.__(key, replace).split('|');

            const plural =
                number % 10 === 1 && number % 100 !== 11
                    ? 0
                    : number % 10 >= 2 &&
                    (number % 100 < 10 || number % 100 >= 20)
                        ? 1
                        : 2;

            return plural <= options.length
                ? options[plural]
                : number === 1
                    ? options[0]
                    : options[1];
        },
    },
}

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import Layout from '@/Layouts/CheckoutLayout.vue';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';

defineOptions({
    layout: Layout,
});

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
});

const interval = ref();
const status = ref(props.transaction.status);
const intervalCycle = ref(0);

const isCompleted = computed(() => status.value !== 0);

watch(() => isCompleted, (val) => {
    if (val === true) {
        clearInterval(interval.value);
    }
});

watch(intervalCycle, (val) => {
    if (val >= 60) {
        clearInterval(interval.value);
    }
});

onMounted(() => {
    checkStatus();

    if (!isCompleted.value) {
        interval.value = setInterval(() => {
            intervalCycle.value++;
            checkStatus();
        }, 10000);
    }
});

const checkStatus = async () => {
    if (isCompleted.value) {
        return;
    }

    await window.axios.get(route('api::transactions.status', props.transaction))
        .then((resp) => status.value = resp.data.status)
        .catch(() => {});
};
</script>

<template>
    <MetaHead :title="__('page.checkout_success.meta_title')"/>

    <div class="flex flex-col items-center justify-center gap-6 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <template v-if="status === 0">
            <ArrowPathIcon class="h-32 w-32 text-[#E89F8D] animate-spin"/>

            <h1
                class="text-2xl font-semibold text-[#272529]"
                v-text="__('text.payment_is_being_verified')"
            />
        </template>

        <template v-else-if="status === 1">
            <div class="flex items-center justify-center bg-[#71D38E] text-white text-sm border rounded-full">
                <CheckIcon class="h-20 w-20 p-2"/>
            </div>

            <h1
                class="text-2xl font-semibold text-[#272529]"
                v-text="__('text.payment_succeed')"
            />

            <PrimaryLink
                :href="route('courses.show', transaction.course.slug)"
                v-text="__('cta.start_course')"
            />
        </template>

        <template v-else>
            <div class="flex items-center justify-center bg-red-600 text-white text-sm border rounded-full">
                <XMarkIcon class="h-20 w-20 p-2"/>
            </div>

            <h1
                class="text-2xl font-semibold text-[#272529]"
                v-text="__('text.payment_failed')"
            />

            <PrimaryLink
                :href="route('dashboard')"
                v-text="__('cta.go_back')"
            />
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
});

const regularPrice = computed(() => props.course.regular_price);
const promoPrice = computed(() => props.course.promo_price);

const hasDiscount = computed(() => {
    if (!promoPrice.value) {
        return false;
    }

    return regularPrice.value > promoPrice.value;
});
</script>

<template>
    <div
        v-if="hasDiscount"
        class="flex gap-2 text-base font-medium text-[#D32F2F]"
    >
        {{ promoPrice }} &euro;
        <span class="text-[#524E56] line-through">{{ regularPrice }} &euro;</span>
    </div>

    <div
        v-else
        class="text-base font-medium text-[#272529]"
    >
        {{ regularPrice }} &euro;
    </div>
</template>

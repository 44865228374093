<script setup>
import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { router } from '@inertiajs/vue3';
import MenuItems from '@/Data/PublicNavbarMenuItems.js';
import NavLink from './Partials/NavLink.vue';
import NavLoginLink from './Partials/NavLoginLink.vue';
import ResponsiveNavLink from './Partials/ResponsiveNavLink.vue';
import ResponsiveNavLoginLink from './Partials/ResponsiveNavLoginLink.vue';

const emit = defineEmits(['scroll']);

const scrollOptions = {
    capture: true,
    passive: true,
};

const isFixed = ref(false);
const showingNavigationDropdown = ref(false);
const lastKnownScrollPosition = ref(0);
const ticking = ref(false);

watch(showingNavigationDropdown, (showing) => {
    const bodyClassList = document.body.classList;

    if (showing) {
        bodyClassList.add('overflow-hidden');
        isFixed.value = true;
        return;
    }

    bodyClassList.remove('overflow-hidden');
    handleWindowScroll();
});

onBeforeMount(() => {
    window.addEventListener('wheel', handleWindowScroll, scrollOptions);
    window.addEventListener('scroll', handleWindowScroll, scrollOptions);
});

onBeforeUnmount(() => {
    window.addEventListener('wheel', handleWindowScroll, scrollOptions);
    window.addEventListener('scroll', handleWindowScroll, scrollOptions);
});

const handleWindowScroll = () => {
    lastKnownScrollPosition.value = window.scrollY;

    if (!ticking.value) {
        window.requestAnimationFrame(() => {
            const position = Math.max(
                window.pageYOffset,
                document.documentElement.scrollTop,
                document.body.scrollTop
            );

            isFixed.value = position > 0;
            ticking.value = false;
        });

        ticking.value = true;
    }
};

const handleNavigate = (item) => {
    showingNavigationDropdown.value = false;

    if (!route().current(item.route)) {
        router.visit(route(item.route));
    }

    if (item.hash) {
        const timeoutId = setTimeout(() => {
            handleScrollToSection(item);
            clearTimeout(timeoutId);
        }, 500);
    }
};

const handleScrollToSection = (item) => {
    const sectionId = item.hash.replace('#', '');
    const section = document.getElementById(sectionId);

    if (section !== undefined) {
        let top = section.offsetTop;

        if (window.innerWidth < 640) {
            top -= 50;
        }

        window.scrollTo({
            top,
            left: 0,
            behavior: 'smooth',
        });
    }
};
</script>

<template>
    <nav
        :class="{
            'fixed bg-white border-[#DEDEE3]': isFixed || showingNavigationDropdown,
            'absolute bg-transparent border-transparent': !isFixed && !showingNavigationDropdown,
            'h-screen': showingNavigationDropdown,
        }"
        class="top-0 left-0 w-full border-b z-20 transition-all px-6"
    >
        <div class="max-w-7xl mx-auto">
            <div
                :class="{
                    'h-16 md:h-20': isFixed,
                    'h-16 md:h-24': !isFixed,
                }"
                class="flex justify-between"
            >
                <div class="flex">
                    <!-- Logo -->
                    <div
                        :class="{
                            'mt-19 md:mt-16': !isFixed && !showingNavigationDropdown,
                        }"
                        class="shrink-0 flex items-center"
                    >
                        <Link :href="route('home')">
                            <img v-show="isFixed || showingNavigationDropdown" src="/img/landing/logo_black.png" alt="" class="h-12 w-12" />
                            <img v-show="!isFixed && !showingNavigationDropdown" src="/img/landing/logo_white.svg" alt=""  class="h-28 w-28"/>
                        </Link>
                    </div>

                    <!-- Navigation Links -->
                    <div class="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex">
                        <NavLink
                            v-for="(item, key) in MenuItems"
                            :key="key"
                            :item="item"
                            :fixed="isFixed"
                            @click.prevent="handleNavigate(item)"
                        />
                    </div>
                </div>

                <div class="hidden sm:flex sm:items-center sm:ms-6">
                    <NavLoginLink :fixed="isFixed"/>
                </div>

                <!-- Hamburger -->
                <div class="flex items-center sm:hidden">
                    <button
                        :class="{
                            'text-[#0F172A]': isFixed || showingNavigationDropdown,
                            'text-white': !isFixed && !showingNavigationDropdown,
                        }"
                        class="inline-flex items-center justify-center rounded-md text-[#0F172A] transition duration-150 ease-in-out"
                        @click="showingNavigationDropdown = ! showingNavigationDropdown"
                    >
                        <svg
                            class="h-8 w-8"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                            <path
                                :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="sm:hidden mt-12">
            <div class="flex flex-col gap-8 mt-8">
                <ResponsiveNavLink
                    v-for="(item, key) in MenuItems"
                    :key="key"
                    :item="item"
                    @click.prevent="handleNavigate(item)"
                />

                <ResponsiveNavLoginLink/>
            </div>
        </div>
    </nav>
</template>

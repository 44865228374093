<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    content: {
        type: String,
        required: true,
    },
});

const paragraphContent = ref(null);

onMounted(() => {
   if (paragraphContent.value) {
       const ulClassList = paragraphContent.value.querySelector('ul')?.classList;
       const olClassList = paragraphContent.value.querySelector('ol')?.classList;

       if (ulClassList) {
           ulClassList.add('list-disc');
           ulClassList.add('pl-4');
       }

       if (olClassList) {
           olClassList.add('list-decimal');
           olClassList.add('pl-4');
       }
   }
});
</script>

<template>
    <div ref="paragraphContent" v-html="content"/>
</template>

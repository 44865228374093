<script setup>
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';
import AnimatedComponent from '@/Components/AnimatedComponent.vue';

const handleScrollToSection = (hash) => {
    const sectionId = hash.replace('#', '');
    const section = document.getElementById(sectionId);

    if (section !== undefined) {
        let top = section.offsetTop;

        if (window.innerWidth < 640) {
            top -= 50;
        }

        window.scrollTo({
            top,
            left: 0,
            behavior: 'smooth',
        });
    }
};
</script>

<template>
    <header class="relative bg-[#272529] overflow-hidden pt-[171px] px-6 pb-36 md:pb-20">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-12 md:grid-cols-2">
                <div class="flex flex-col justify-center">
                    <h1
                        class="text-5xl text-white"
                        v-html="__('page.home.header.title')"
                    />

                    <p
                        class="text-lg text-[#DEDEE3] mt-4"
                        v-text="__('page.home.header.subtext')"
                    />

                    <div class="flex justify-between gap-4 mt-8 md:justify-start md:gap-6">
                        <PrimaryLink :href="route('courses.index')" class="w-full text-center !text-base !px-8 md:w-auto">
                           {{ __('page.home.header.btn_courses') }}
                        </PrimaryLink>

                        <a
                            href="#"
                            class="w-full inline-block bg-white text-base font-semibold text-center text-[#272529] border border-transparent rounded-lg px-8 py-3 md:w-auto"
                            @click.prevent="handleScrollToSection('services')"
                        >
                            {{ __('page.home.header.btn_services') }}
                        </a>
                    </div>
                </div>

                <div class="ml-auto z-10">
                    <div class="relative">
                        <img src="/img/landing/header.png" alt=""/>

                        <AnimatedComponent>
                            <div class="absolute -bottom-[120px] -translate-x-1/2 left-1/2 flex items-start gap-2 w-full px-4 md:-bottom-[30px] md:-left-[40px] md:translate-x-0 md:px-0">
                                <img src="/img/landing/header_avatar.png" alt=""/>

                                <div class="flex flex-col gap-2 max-w-[300px] bg-white text-[#272529] rounded-r-lg rounded-bl-lg p-8">
                                    <div class="font-semibold" v-text="__('page.home.header.comment_author')"/>
                                    <div v-text="__('page.home.header.comment_text')"/>
                                </div>
                            </div>
                        </AnimatedComponent>

                        <div class="absolute top-0 left-0 h-[58px] w-[58px] bg-[#D0D0C4] rounded-tl-[16px]"></div>
                        <div class="absolute top-[58px] left-0 h-[58px] w-[58px] bg-[#E89F8D]"></div>
                        <div class="absolute top-0 left-[58px] h-[58px] w-[58px] bg-[#E4EFF1]"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="absolute bottom-0 -right-[50px] h-[40px] w-full bg-[#C9DEE2] -rotate-[15deg] md:w-3/4 md:bg-[#E89F8D]"/>
    </header>
</template>

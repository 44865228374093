<script setup>
import { useString } from '@/Composables/useString.js';
import CourseExperienceLevel from '@/Components/Course/CourseExperienceLevel.vue';
import CourseLessonsCount from '@/Components/Course/CourseLessonsCount.vue';
import CoursePrice from '@/Components/Course/CoursePrice.vue';

const { truncate } = useString();

defineProps({
    course: Object,
});
</script>

<template>
    <div class="bg-white rounded-lg hover:cursor-pointer hover:drop-shadow-md">
        <Link :href="route('courses.show', course.slug)">
            <img :src="course.image_url" alt="" class="w-full"/>

            <div class="p-6">
                <h2
                    class="font-semibold text-[#272529]"
                    v-text="course.title"
                />

                <p
                    v-if="course.content"
                    class="text-sm text-[#524E56] mt-2.5"
                    v-html="truncate(course.content, 120)"
                />

                <div class="block h-px bg-[#DEDEE3] mt-4"/>

                <div class="flex flex-col gap-3 mt-4.5">
                    <CourseExperienceLevel :level="course.experience_level"/>
                    <CourseLessonsCount :count="course.lessons_count"/>
                </div>

                <CoursePrice :course="course" class="mt-4"/>
            </div>
        </Link>
    </div>
</template>

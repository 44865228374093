<script setup>
import { computed } from 'vue';

const props = defineProps({
    level: {
        type: Number,
        default: 1,
    },
});

const label = computed(() => {
    switch (props.level) {
        case 1: {
            return 'for_beginner'
        }
        case 2: {
            return 'for_experienced'
        }
        case 3: {
            return 'for_very_experienced'
        }
        default: {
            return null
        }
    }
});
</script>

<template>
    <div v-if="label" class="flex items-center gap-2.5 text-sm text-[#524E56]">
        <img :src="`/img/icons/level-${level}.svg`" alt=""/>
        {{ __(`text.${label}`) }}
    </div>
</template>

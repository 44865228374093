import { router } from '@inertiajs/vue3'

router.on('navigate', (event) => {
    window.omnisend.push(['track', '$pageViewed']);

    window.dataLayer.push({
        event: 'page_view',
        url: event.detail.page.url,
    });
});
